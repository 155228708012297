.spinner {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  }
  
  .dot1, .dot2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: dot2 2.0s infinite ease-in-out;
    animation: dot2 2.0s infinite ease-in-out;
  }
  
  .dot2 {
    -webkit-animation: dot1 2.0s infinite ease-in-out;
    animation: dot1 2.0s infinite ease-in-out;
  }
  
  @-webkit-keyframes dot1 {
    0% { transform: scale(0.0) }
    50% { transform: scale(1.0) }
    100% { transform: scale(0.0) }
  }
  
  @keyframes dot1 {
    0% { transform: scale(0.0) }
    50% { transform: scale(1.0) }
    100% { transform: scale(0.0) }
  }
  
  @-webkit-keyframes dot2 {
    0% { transform: scale(1.0) }
    50% { transform: scale(0.0) }
    100% { transform: scale(1.0) }
  }
  
  @keyframes dot2 {
    0% { transform: scale(1.0) }
    50% { transform: scale(0.0) }
    100% { transform: scale(1.0) }
  }
  