@media print {
  @page {
    size: A4;
    margin: 10mm;
  }

  

  #print-invoice-modal, #print-invoice-modal * {
    visibility: visible; /* Make the print modal content visible */
  }
  #print-invoice-modal {
    position: relative;
    width: 100%;
    height: auto;
    overflow: visible;
    margin: 0;
    padding: 0;
    box-shadow: none;
  }

  /* Set a fixed height for content and footer separation */
  #printableArea {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 20mm;
    margin-bottom: 20mm; /* Ensure space for footer */
    padding-bottom: 20mm; /* Space between content and blue line */
  }

 

  .modal {
    position: absolute;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: auto;
    background: white;
    padding: 20px;
    z-index: 1000;
    overflow: visible;
  }
  
  body, html {
    width: 100%;
    height: auto;
    overflow: visible;
    margin: 0;
    padding: 0;
  }

  #my-modal {
    position: absolute;
    left: 0;
    top: 0;
  }

  .print\:bg-white {
    background-color: #fff !important;
  }

  .print\:shadow-none {
    box-shadow: none !important;
  }

  .logo img {
    width: 150px;
    height: 150px;
  }

  .flex-shrink-0 {
    flex: 0 0 150px;
  }

  .print\:border-t {
    border-top-width: 1px;
  }

  .print\:text-center {
    text-align: center;
  }

  .print\:text-xs {
    font-size: 0.75rem;
  }

  .print\:mt-4 {
    margin-top: 1.5rem;
  }

  .print\:p-4 {
    padding: 1rem;
  }
}

.flex-shrink-0 {
  flex: 0 0 150px;
}
