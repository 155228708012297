.job-card-container {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  }
  
  .job-card-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  
  .vehicle-reg-number {
    color: #ff0000;
    font-size: 1rem;
    font-weight: 600;
  }
  
  .job-card-form {
    display: grid;
    gap: 1.5rem;
  }
  
  .form-control {
    display: grid;
    gap: 0.5rem;
  }
  
  .form-label {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
  }
  
  .form-input, .form-textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border 0.3s ease;
  }
  
  .form-input:focus, .form-textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .form-submit-btn {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .form-submit-btn:hover {
    background: #0056b3;
  }
  