/* VehicleInspectionReport.css */

.report-container {
    display: flex;
    flex-direction: column;
    /* max-width: 1200px; */
    max-width: 210mm;
    margin: auto;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .a4-height {
    height: 297mm;

  }


  .report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    font-family: 'Helvetica', 'Arial', sans-serif;
    background-color: #f8f8f8;
    border-bottom: 1px solid #eaeaea;
  }
  
  .report-title h1 {
    font-size: 24px;
    color: #333;
  }
  
  .report-title p {
    font-size: 16px;
    color: #666;
  }
  
  .print-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .chart-container {
    width: 100%;
    padding: 20px;
  }
  
  .status-summary {
    padding: 20px;
  }
  
  .component-details {
    padding: 20px;
  }
  
  .table-auto {
    width: 100%;
    margin-top: 10px;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background-color: #f8f8f8;
    border-top: 1px solid #eaeaea;
  }
  
  .pagination-controls button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .pagination-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  @media (max-width: 760px) {
    .report-container {
      flex-direction: column;
    }
  
    .report-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .print-button {
      margin-top: 10px;
    }
  
    .chart-container,
    .status-summary,
    .component-details {
      padding: 10px;
    }
  
    .pagination-controls {
      flex-direction: column;
      align-items: center;
    }
  
    .pagination-controls button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  
  /* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

/* Modal Container */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

/* Modal Open State */
.modal.modal-open {
  opacity: 1;
  visibility: visible;
}

/* Modal Content */
.modal-content {
  margin-top: 20px;
}

/* Modal Title */
.modal-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

/* Modal Text Area */
.modal-textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

/* Modal Actions */
.modal-actions {
  display: flex;
  justify-content: flex-end;
}

/* Modal Buttons */
.modal-button {
  padding: 6px 12px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #0056b3;
}



.image-gallery-img {
  height: 200px; /* Adjust height as needed */
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem; /* Adjust for rounded corners */
}

.image-caption {
  margin-top: 0.5rem; /* Spacing between image and caption */
  font-size: 0.875rem; /* Adjust font size as needed */
  color: #4a5568; /* Adjust caption text color */
}


.print-friendly-container {
  width: 210mm; /* A4 width */
  max-width: 210mm;
  margin: auto;
}


@media print {

  .h-a4 {
    height: 297mm; /* Height of A4 paper */
  }
  .print-version-button {
    display: inline-block; /* Show button for screen */
  }
  .report-details {
    page-break-after: always; /* Ensure this section is on its own page */
  }
  .chart-container {
    height: auto; /* Adjust height for printing */
  }

  .print-friendly-container {
    width: 210mm; /* A4 width */
    max-width: 210mm;
    margin: auto;
  }

  .a4-size {
    height: 297mm; /* The height of A4 paper is 297mm */
    width: 210mm; /* The width of A4 paper is 210mm */
    page-break-after: always; /* This will ensure that the section breaks onto a new page when printed */
    page-break-inside: avoid; /* This prevents the content inside the div from being split across pages */
    overflow: hidden; /* This ensures that the content that overflows the dimensions is hidden */
  }


}

  
  /* Style other elements as needed for printing */

