@media print {
  body * {
    visibility: hidden; 
  }
  #print-invoice-modal, #print-invoice-modal * {
    visibility: visible; /* Only printableArea will be visible */
  }
  #print-invoice-modal {
    position: absolute;
    left: 0;
    top: 20px;
    width: 100%; /* Take the full width */
    height: 100%; /* Take the full height */
  }

  #printableArea {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 20mm;
    padding-bottom: 20mm; /* Create space between content and the blue footer */
    margin-bottom: 20mm; /* Ensure footer space */
  }
}